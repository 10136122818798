<template>
    <div
        class="text-center"
        style="display: flex; align-items: center; justify-content: center; flex-direction: column; height:100%;"
    >
        <div>
            <v-progress-circular
                :size="80"
                indeterminate
                color="primary"
            >
                <v-icon
                    v-if="icon !== null"
                    color="primary"
                    large
                >
                    {{ icon }}
                </v-icon>
            </v-progress-circular>

            <v-progress-linear
                v-if="showLoadBar"
                class="my-2"
                :value="chunkPercent"
            />
        </div>
        <div
            v-if="!showLoadBar"
            class="mt-2"
        >
            {{ text }}
        </div>
        <div
            v-if="showLoadBar"
            class="mt-2"
        >
            {{ text }} {{ chunkPercent }} %
        </div>
    </div>
</template>

<script>

export default {
    props: {
        icon: {
            type: String,
            required: false,
            default: 'mdi-car'
        },
        text: {
            type: String,
            default: 'Henter data...'
        },
        showLoadBar: {
            type: Boolean,
            default: false
        },
        totalChunks: {
            type: Number,
            default: 1
        },
        currentChunk: {
            type: Number,
            default: 1
        }
    },
    computed: {
        chunkPercent() {
            return (this.currentChunk * 100 / this.totalChunks).toFixed(0)
        }
    },
    methods: {
    }

}
</script>
